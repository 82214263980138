<template>
	<div class="mdc-select" :class="{'mdc-select--disabled': $attrs.disabled }">
		<input type="hidden" :name="name" :id="id" v-bind="attrs" v-on="listeners" />
		<div class="mdc-select__anchor"
			role="button"
			aria-haspopup="listbox"
			aria-labelledby="demo-label demo-selected-text">
			<span id="selected-text" class="mdc-select__selected-text">{{ selected }}</span>
			<span class="mdc-select__dropdown-icon">
				<svg
					class="mdc-select__dropdown-icon-graphic"
					viewBox="7 10 10 5">
					<polygon
						class="mdc-select__dropdown-icon-inactive"
						stroke="none"
						fill-rule="evenodd"
						points="7 10 12 15 17 10">
					</polygon>
					<polygon
						class="mdc-select__dropdown-icon-active"
						stroke="none"
						fill-rule="evenodd"
						points="7 15 12 10 17 15">
					</polygon>
				</svg>
			</span>
			<span class="mdc-select__suffix" v-if="$slots.post"><slot name="post"></slot></span>
			<span id="select-label" class="mdc-floating-label" :class="{'mdc-floating-label--float-above': attrs.value !== undefined}" v-html="attrs.required ? `${label}*` : label"></span>
		</div>

		<div class="mdc-select__menu mdc-menu mdc-menu-surface" role="listbox">
			<ul class="mdc-list" :class="{ 'mdc-list--two-line': twoLine }">
				<li class="mdc-list-item"
					role="option"
					:aria-selected="option.value === $attrs.value"
					:class="{'mdc-list-item--selected': option.value === $attrs.value}"
					:data-value="option.value"
					:key="key"
					v-for="(option, key) in options"
					>
					<span class="mdc-list-item__text">
						<span :class="{'mdc-list-item__primary-text': twoLine }" v-html="option.label"></span>
						<span class="mdc-list-item__secondary-text" v-html="option.alt" v-if="option.alt && twoLine"></span>
					</span>
				</li>
			</ul>
		</div>
	</div>
<!--
	<div class="mdc-select">
		<i class="mdc-select__dropdown-icon"></i>
		<span class="mdc-select__selected-text"></span>
		<select
			class="mdc-select__native-control"
			v-bind="attrs"
			v-on="listeners"
			:aria-label="label"
			:id="id"
			:name="name"
		>
			<option value="" :selected="!attrs.value" disabled></option>
			<option
				:value="option.value"
				:key="key"
				v-for="(option, key) in options"
			>
				{{ option.label }}
			</option>
		</select>
		<label class="mdc-floating-label" :for="id" :class="{'mdc-floating-label--float-above': attrs.value}">{{ label }}</label>
	</div> -->
</template>

<script>
import { MDCSelect } from '@material/select'
import { v4 } from 'uuid'

export default {
	name: 'selectField',
	data: () => ({
		select: null,
	}),
	props: {
		options: {
			type: [Array],
			default: () => ([]),
		},
		id: {
			type: String,
			default: v4(),
		},
		name: String,
		label: String,
		twoLine: false,
	},
	computed: {
		attrs() {
			return this.$attrs
		},
		listeners() {
			return {
				...this.$listeners,
				input: event => this.input(event),
				change: event => this.input(event),
			}
		},
		selected() {
			const found = this.options.find((option) => option.value == this.attrs.value)
			if (found) {
				return found.label
			}
			return ''
		},
	},
	created() {
		this.unwatch = this.$watch('options', (newVal)  => {
			this.select = new MDCSelect(this.$el)
			this.select.listen('MDCSelect:change', () => {
				if (!this.options) return
				let _selected = this.options[this.select.selectedIndex]
				if (_selected != undefined && _selected != null) {
					this.$emit('input', _selected.value)
				}
			}, {
				immediate: true,
			})
			this.unwatch()
		})
	},
	mounted() {
		if (!this.select) {
			this.select = new MDCSelect(this.$el)
			this.select.listen('MDCSelect:change', () => {
				if (!this.options) return
				let _selected = this.options[this.select.selectedIndex]
				if (_selected != undefined && _selected != null) {
					this.$emit('input', _selected.value)
				}
			})
		}
	},
	methods: {
		input(event) {
			if (event && event.target != undefined && event.target != null) {
				this.$emit('input', event.target.value)
			}
		},
	},
	watch: {
		attrs(val) {
			if (val && (val.value == undefined || val.value == null) && this.select) {
				this.select.selectedIndex = -1
			}

			if (this.select && (val && val.value != undefined && val.value != null) && (this.options && this.options.length) && (this.select.menuItemValues && this.select.menuItemValues.length)) {
				const idx = this.options.findIndex(o => o.value == val.value)
				const option = this.options.find(o => o.value == val.value)
				if (idx > -1)
					this.select.foundation.adapter.setSelectedIndex(idx)
				if (option)
					this.select.foundation.adapter.setSelectedText(option.label)
			}
		},
		options(val, oldVal) {
			if (val != oldVal) {
				this.select.layoutOptions()
				this.select.selectedIndex = -1
			}
		},
		selected: {
			immediate: true,
			handler (val) {
				if (val && this.$el) {
					const text = this.$el.querySelector('#selected-text')
					const label = this.$el.querySelector('#select-label')
					if (text) {
						text.innerHTML = val
					}
					if (label && label.classList) {
						label.classList.add('mdc-floating-label--float-above')
					}
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@use "@material/list/mdc-list";
@use "@material/menu-surface/mdc-menu-surface";
@use "@material/menu/mdc-menu";
@use "@material/select/mdc-select";

.mdc-select {
	$select: &;

	&__menu {
		width: 100%;
		z-index: 25;
	}

	&--basic {
		#{$select}__anchor {
			background-color: transparent;
			border-width: 0;
		}
	}

	&:not(#{$select}--disabled) {

		&#{$select}--basic {
			#{$select}__anchor {
				background-color: transparent !important;
				border-width: 0;
			}
		}

		&#{$select}--focused {
			.mdc-floating-label {
				color: modules.color_('primary');
			}
		}
	}

	&--activated {
		#{$select}__anchor {
			border-color: modules.color_('primary');
		}
	}

	&__anchor {
		// border-bottom-color: rgba(0, 0, 0, 0.38);
		// border-left-color: rgba(0, 0, 0, 0.38);
		// border-right-color: rgba(0, 0, 0, 0.38);
		// border-top-color: rgba(0, 0, 0, 0.38);

		// #{$select}:hover & {
		// 	border-bottom-color: rgba(0, 0, 0, 0.87);
		// 	border-left-color: rgba(0, 0, 0, 0.87);
		// 	border-right-color: rgba(0, 0, 0, 0.87);
		// 	border-top-color: rgba(0, 0, 0, 0.87);

		// }
		#{$select}__ripple {
			&:before {
				background-color: transparent;
			}
		}
	}

	&__suffix {
		// align-self: center;
		padding-right: 1rem;
		color: rgba(0, 0, 0, 0.6);
	}

	.mdc-list-item {
		&:hover,
		&:focus {
			background-color: rgba(220,220,220,0.5);
		}

		&.mdc-list-item--selected {
			background-color: rgba(modules.color_('primary'), 1);
			color: modules.color_('primary', 'text');

			.mdc-list-item__secondary-text {
				color: rgba(255,255,255,0.8) !important;
			}
		}
	}
}
</style>
